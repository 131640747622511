@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("icon");

@mixin c-generate-icon-sizes{
    @each $i, $key in tokenIconSizes() {
        .#{$component}--size-#{$i}{
            font-size:tokenIconSize($i);
        }
    }
    @each $i, $props in tokenIconSizes() {
        @each $k in tokenTypoBreakpoints() {
            @include media-breakpoint-up(#{$k}) {
                .#{$component}--size-#{$k}-#{$i}{
                    font-size:#{tokenIconSize($i)};
                }
            }
        }
    }
}