$zaux-token-ratios: (
  1: (
    type: ratio,
    ratio: 16 / 9,
    ratioEscaped: '16 / 9'
  ),
  2: (
    type: ratio,
    ratio: 4 / 3,
    ratioEscaped: '4 / 3'
  ),
  3: (
    type: ratio,
    ratio: 1 / 1,
    ratioEscaped: '1 / 1'
  ),
  4: (
    type: ratio,
    ratio: 1 / 2,
    ratioEscaped: '1 / 2'
  ),
  5: (
    type: ratio,
    ratio: 3 / 1 ,
    ratioEscaped: '3 / 1'
  )
);
