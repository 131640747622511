@charset "UTF-8";

$component: componentClass("iconbox2");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    color: tokenColor("set1","color4");
    &__head{
        border-bottom-color:tokenColor("set1","color5");
        border-bottom-style:solid;
    }
    &__title{
        color:tokenColor("set1","color1");
    }
    &--theme{
        &-alt1{
            color:tokenColor("set1","white1");
            .#{$component}__head{
                border-bottom-color:tokenColor("set1","white1");
            }
            .#{$component}__title{
                color:tokenColor("set1","white1");
            }
        }
    }
}