@charset "UTF-8";

$component: componentClass("badge");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component} {

    background-color:tokenColor("set1", "color1");
    color:tokenColor("set1", "white1");
    font-weight: $zaux-typo-fw-bold;

}