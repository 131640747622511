@charset "UTF-8";


//@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn2");
$cmp-tokens: ();

/*
@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}
*/

@mixin c-btn-size($px) {
  text-align: center;

  .#{$component} {
    &__inner {
      font-size: rem($px);
      border: 1px solid transparent;
      height: 1em;
      border-radius: 0.5em;
      display: block;
    }
  }
}

[class*="#{$component}--"] {
  // Commented in favor of Bootstrap's `$line-height-base: 0;` (see src/assets/scss/abstracts/_variables.scss)
  //font-size: 0;
  //line-height: 0;

  &,
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.#{$component} {

  display:inline-block;
  text-transform:uppercase;
  font-weight: cmpToken($cmp-tokens, 'fw-btn', $zaux-typo-fw-regular, 'layout');
  letter-spacing: cmpToken($cmp-tokens, 'ls-btn', rem(tokenLetterSpacing(1)), 'layout');
  text-align:center;
  transition: all $zaux-transition-1;

  .#{$component}__label{
    font-size:cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
    font-weight:$zaux-typo-fw-semibold;
  }

  .#{$component}__glyph{
    transition: transform $zaux-transition-1;
  }

  &:hover{
    .#{$component}__glyph{
        transform:translateY(3px);
    }
  }

  &__inner{
    display:flex;
    flex-direction:column;
  }

  //Small
  &--size-s{
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnsm', tokenTypoSizeFs(1), 'layout');
      line-height: cmpToken($cmp-tokens, 'lh-btnsm', tokenTypoSizeLh(1), 'layout');
    }
    .#{$component}__glyph{
      margin-bottom: cmpToken($cmp-tokens, 'spacer-iconsm-mb', tokenSpacer(2), 'layout');
      svg{
        height:24px;
        width:16px;
      }
    }
  }

  //Default and medium
  &--size-m, &:not([class*=--size]){
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
      line-height: cmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(1), 'layout');
    }
    .#{$component}__glyph{
      margin-bottom: cmpToken($cmp-tokens, 'spacer-iconmedium-mb', tokenSpacer(4), 'layout');
      @include media-breakpoint-down(md){
        margin-bottom: cmpToken($cmp-tokens, 'spacer-iconmedium-mb', tokenSpacer(3), 'layout');
        svg{
          width: tokenSpacer(4);
          height:tokenSpacer(5);
        }
      }
    }
  }

  //Large
  &--size-l{
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnlarge', tokenTypoSizeFs(3), 'layout');
      line-height: cmpToken($cmp-tokens, 'lh-btnlarge', tokenTypoSizeLh(3), 'layout');
    }
    .#{$component}__glyph{
      margin-bottom: cmpToken($cmp-tokens, 'spacer-iconlg-mb', tokenSpacer(4), 'layout');
      svg{
        height:36px;
        width:24px;
      }
    }
  }

  //XLarge
  &--size-xl{
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnlarge', tokenTypoSizeFs(4), 'layout');
      line-height: cmpToken($cmp-tokens, 'lh-btnlarge', tokenTypoSizeLh(4), 'layout');
    }
    .#{$component}__glyph{
      margin-bottom: cmpToken($cmp-tokens, 'spacer-iconlg-mb', tokenSpacer(5), 'layout');
      svg{
        height:68px;
        width:116px;
      }
    }
  }

  @mixin btn2-inline-styling{
    .#{$component}{
      &__inner{
        flex-direction:row;
      }
      &__glyph, &__label{
        display:inline-block;
        vertical-align:top;
        margin-bottom:0px;
      }      
    }
    &.#{$component}{
      .#{$component}__label{
        padding-top:tokenSpacer(1) / 2;
      }
      &:hover{
        .#{$component}__glyph{
            transform:translateY(0px);
        }
        .#{$component}__label{
          transform:translateX(#{tokenSpacer(1)});
        }
      }
      &--size{
        &-xl{
          .#{$component}__glyph{
            margin-right:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(3), 'layout' );
          }
          svg{ width:64px; }
        }
        &-l{
          .#{$component}__glyph{
            margin-right:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(3), 'layout' );
          }
          .#{$component}__label{
            padding-top:tokenSpacer(2);
          }
          svg{ width:32px; height:48px; }
        }
        &-m{
          .#{$component}__glyph{
            margin-right:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(4), 'layout' );
          }
          svg{
            width: 24px;
            height:36px;
          }
          .#{$component}__label{
            margin-top:tokenSpacer(1);
            @include media-breakpoint-down(md){
              margin-top:0;
            }
          }
          @include media-breakpoint-down(md){
            svg{ width:16px; height:24px; }
          }
        }
        &-s{
          .#{$component}__glyph{
            margin-right:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(2), 'layout' );
          }
          svg{ width:16px; }
        }
      }
      &--glyph-size{
        &-l{
          .#{$component}__glyph{
            margin-right:0;
          }
          .#{$component}__label{
            padding-top: cmpToken($cmp-tokens, 'spacer-glyphsizel-pt', tokenSpacer(4), 'layout' );
            padding-left: tokenSpacer(4);
          }
        }
      }
      &[class*=--inverted]{
        &[class*=--glyph-size-l]{
          .#{$component}__glyph{
            margin-left:0;
          }
          .#{$component}__label{
            padding-left:0;
            padding-right: tokenSpacer(4);
          }
        }
      }
    }
  }

  &--inline{
    @include btn2-inline-styling;
  }

  &--inverted{
    .#{$component}__inner{
      flex-direction:row-reverse;
    }
    &.#{$component}{
      &:hover{
        .#{$component}__label{
          transform:translateX(-#{tokenSpacer(1)});
        }
      }
      &--size{
        &-xl{
          .#{$component}__glyph{
            margin-right:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(3), 'layout' );
          }
          svg{ width:78px; }
        }
        &-l{
          .#{$component}__glyph{
            margin-right:0;
            margin-left:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(3), 'layout' );
          }
        }
        &-m{
          .#{$component}__glyph{
            margin-right:0;
            margin-left:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(2), 'layout' );
          }
        }
        &-s{
          .#{$component}__glyph{
            margin-right:0;
            margin-left:cmpToken($cmp-tokens, 'spacer-inlineiconlg-mr', tokenSpacer(1), 'layout' );
          }
        }
      }
    }
  }

  &--glyph-size{
    &-l{
      .#{$component}__glyph{
        margin-right:cmpToken($cmp-tokens, 'spacer-glyphsizel-ml', tokenSpacer(2), 'layout' );
        svg{
          height:96px;
          width:58px;
        }
      }
      .#{$component}__label{
        padding-top: cmpToken($cmp-tokens, 'spacer-glyphsizel-pt', tokenSpacer(2), 'layout' );
      }
    }
  }

  &--nolabel{
    .#{$component}__glyph{
      margin:0 !important;
    }
  }

  &--dotted{
    &.#{$component}--size{
      &-s{
        .#{$component}__glyph{
          margin-right:tokenSpacer(4);
        }
        .#{$component}__label{
          padding-top:0;
        }
        &:hover{
          .#{$component}__label{
            transform:translateX(2px);
          }
        }
      }
    }
  }

  //Responsive
  &--res1{
    @include media-breakpoint-down(md){
      @include btn2-inline-styling;
      &.#{$component}--glyph-size{
        &-l{
          .#{$component}__label{
            padding-top: cmpToken($cmp-tokens, 'spacer-glyphsizel-pt', tokenSpacer(7), 'layout' );
          }
        }
      }
    }
  }

}
