@charset "UTF-8";

$component: componentClass("authorsection");
$cmp-tokens: ();

.#{$component} {

    &--has-flap{
        position:relative;
        &:before{
            position:absolute;
            left:50%;
            transform:translateX(-50%) rotate(180deg);
            content:"";
            border-width: 0 tokenSpacer(7) tokenSpacer(7) 0;
            top:-#{tokenSpacer(7)};
            z-index:tokenZindex(2);
        }
    }

    &--meta-abs{
        .#{$component}__inner{
            position:relative;
            .#{$component}__meta{
                width:100%;
                position:absolute;
                bottom:-#{tokenSpacer(8)-0.2};
                right:tokenSpacer(5);
                @include media-breakpoint-down("md"){
                    position:static;
                    right:0;
                }
            }
        }
    }
}