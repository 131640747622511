@charset "UTF-8";

$component: componentClass("footer");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component} {
    background-color: tokenColor('set1', 'color1');
    color: tokenColor('set1', 'white1');  
    &__row{
        &--1{
            @include media-breakpoint-down("md"){
                border-bottom-color:tokenColor("set1","white1");
            }
        }
        &--2{
            border-top-color:tokenColor("set1","white1");
            border-top-style: solid;
        }
    }
}
