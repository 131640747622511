@charset "UTF-8";

$component: componentClass("contentquote");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    &__author{
        border-color:tokenColor("set1","color4");
        border-right-style:solid;
    }
}