@charset "UTF-8";

$component: componentClass("btn");
$cmp-tokens: ();

$c-btn-hover-color-shift-factor: 10%;

@mixin button-style1($bgcolor, $textcolor){

    background-color:$bgcolor;
    color:$textcolor;
    transition:background-color $zaux-transition-1;
    position:relative;

    &:is(input){
        background-color: $bgcolor !important;
        color:$textcolor !important;
    }
    
    .#{$component}__inner{
        .#{$component}__label{
            color:$textcolor;
        }
    }

    &:after{
        @include comicFlap($bgcolor);
        transition:border-color $zaux-transition-1;
        content:"";
        position:absolute;
        background-size:100%;
    }

    &:hover{
        background-color:darken($bgcolor, $c-btn-hover-color-shift-factor);
        &:after{
            @include comicFlap(darken($bgcolor, $c-btn-hover-color-shift-factor));
        }
        &:is(input){
            background-color:darken($bgcolor, $c-btn-hover-color-shift-factor) !important;
        }
    }
    
    &:active, &.active{
        background-color:darken($bgcolor, $c-btn-hover-color-shift-factor + 5);
        &:after{
            @include comicFlap(darken($bgcolor, $c-btn-hover-color-shift-factor + 5));
        }
        
    }
    
    &:is(input[type=submit]){
        color: $textcolor;
    }
}


////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////




.zaux-theme--#{$zaux-theme-light1} .#{$component} {
    font-weight:$zaux-typo-fw-medium;
    &--primary{
        @include button-style1(
            cmpToken($cmp-tokens, 'color-primary-bg', tokenColor('set1','color1'), 'theme-light1'),
            cmpToken($cmp-tokens, 'color-primary-text', tokenColor('set1','white1'), 'theme-light1')
        );
        &:is([type=button]){
            @include button-style1(
                cmpToken($cmp-tokens, 'color-primary-bg', tokenColor('set1','color1'), 'theme-light1'),
                cmpToken($cmp-tokens, 'color-primary-text', tokenColor('set1','white1'), 'theme-light1')
            );
        }
    }
    &--secondary{
        @include button-style1(
            cmpToken($cmp-tokens, 'color-secondary-bg', tokenColor('set1','color2'), 'theme-light1'),
            cmpToken($cmp-tokens, 'color-secondary-text', tokenColor('set1','white1'), 'theme-light1')
        );
    }
    &[disabled]{
        opacity:cmpToken($cmp-tokens, 'opacity-btndisabled', 0.5, 'theme-light1');
    }
}