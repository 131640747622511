@charset "UTF-8";

$component: componentClass("img");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    img{
        transition: all $zaux-transition-0;
    }
    img[class*=lazyload]:not([class*=-is-loaded]){
        display:none;
        opacity:0.4;
    }
}