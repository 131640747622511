@charset "UTF-8";

$component: componentClass("casedevice");
$cmp-tokens: ();

.#{$component} {
    &__screen{
        .#{$component}__screen-frame{
            aspect-ratio: tokenRatio(1);
            border-width: 1px;
            border-style:solid;
            border-radius: tokenRadius(2);
            img{
                border-radius:tokenRadius(0);
            }
        }
        .#{$component}__screen-stand{
            height:tokenSpacer(12);
            width: tokenSpacer(14);
            border-bottom-width:0px;
        }
        .#{$component}__screen-stand-bottom{
            width: tokenSpacer(15);
            height:tokenSpacer(4);
        }
    }
}   