$component: componentClass("iconbox");
$cmp-tokens: ();

.#{$component}{
    min-height:168px;
    max-width:168px;

    @include media-breakpoint-down(md){
        min-height:160px;
        max-width:160px;
    }
    
    &--mw{
        min-width:166px;
        @include media-breakpoint-down("lg"){
            min-width:auto;
        }
        @include media-breakpoint-down("md"){
            min-width:150px;
        }
    }

    > a{ min-height:inherit; }
    &__inner{
        min-height:inherit;
    }

    &:after{
        border-width: 0 50px 48px 0;
        left:50%;
        bottom:-48px;
        position:absolute;
        transform:translateX(-50%) rotate(180deg) scale(-1);
    }
    
}