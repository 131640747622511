@charset "UTF-8";

$component: componentClass("searchoverlay");
$cmp-tokens: ();

.#{$component} {
    height:100%;
    width:100%;
    position:fixed;
    z-index:tokenZindex(5);
    display:none;

    &__dialog{
        height:100%;
        width:100%;
    }

    &__search{
        position:relative;
        transition: top $zaux-transition-0;
        .#{$component}__close{
            position:absolute;
            right:tokenSpacer(6);
            top:50%;
            transform:translateY(-50%);
        }
    }

    &__no-results{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        width:100%;
        padding: 0 tokenSpacer(8);
        opacity:0;
        pointer-events:none;
        &.--show{
            opacity:1;
        }
    }
    
    &__content{
        transition:top $zaux-transition-1;
        padding-top:var(--header-height);
        height: 100%;
        position:relative;
        top:calc( (100vh + var(--header-height)) * -1);
    }

    &__loader{
        position:absolute;
        transform:translate(-50%,-50%);
        top:50%;
        left:50%;
        opacity:0;
        transition:opacity $zaux-transition-1;
        pointer-events:none;
        &.--active{
            opacity:1;
        }
    }

    &__listing{
        display:none;
    }

    [data-loader]{
        z-index:tokenZindex(10);
    }

    &.fade.show{
        opacity:1;
        .#{$component}{
            &__content{
                //top:var(--header-height);
                top:0;
            }
        }
    }

    &--typing{
        .#{$component}__listing{
            display:block;
        }
    }

}