@charset "UTF-8";

//@import "../../assets/scss/components/tokens/header.style";

$component: componentClass("header");
$cmp-tokens: ();

@if (variable-exists(zaux-header-style-tokens)){
    $cmp-tokens : $zaux-header-style-tokens;
}

.#{$component} {

    position:fixed;
    width:100%;
    top:0;
    left:0;
    right:0;
    z-index:tokenZindex(5);
    
    &__row{
        &--1{
            position:relative;
            z-index:tokenZindex(2);
        }
        &--2{
            /*
            top:-70px;
            position:absolute;
            width:100%;
            */
            position:relative;
            transition: all $zaux-transition-0;
            &.collapse:not(.show){
                *{
                    display:none;
                }
            }
        }
    }
    
   .#{$component}__row{
        &--2{
            &.show{
                /*
                top:70px;
                @include media-breakpoint-down(md){
                    top:55px;
                }
                */
            }
        }
    }

    &__logo{
        width:210px;
        height:100%;

        padding: tokenSpacer(5) tokenSpacer(6);
        margin-right: tokenSpacer(11);

        @include media-breakpoint-down("md"){
            margin-right:0;
            padding: tokenSpacer(4);
            width:170px;
            min-width:170px;
            img{
                width:120px;
                height:auto;
            }
        }

        &:after{
            top:50%;
            transform:translateY(-50%) rotate(270deg);
            border-width: 0 tokenSpacer(7) tokenSpacer(7) 0;
            right:-#{tokenSpacer(7)};
            content:"";
            position:absolute;
            @include media-breakpoint-down("md"){
                border-width: 0 tokenSpacer(6) tokenSpacer(6) 0;
                right:-#{tokenSpacer(6)};
            }
        }
        &-area{
            height:100%;
        }
    }
    &__motto{
        letter-spacing:tokenLetterSpacing(1);
        font-size:tokenTypoSizeFs(1);
        line-height:tokenTypoLineHeight(1);
    }
    &__controls{

        overflow:hidden;
        padding: tokenSpacer(5) tokenSpacer(6);

        @include media-breakpoint-down("md"){
            padding: tokenSpacer(4) tokenSpacer(5);
        }

        > * {
            &:first-child{
                margin-left:0 !important;
            }
            &:last-child{
                margin-right:0 !important;
            }
        }
        button{
            line-height:0;
        }
    }
    .#{$component}__hamburger{
        height:24px;
        width:tokenSpacer(6);
        appearance:none;
        border:none;
        background:none;
        transition:all $zaux-transition-0;

        &-inner{
            height:100%;
            width:100%;
            position:relative;
            display:block;
            .#{$component}__hamburger-line{
                &--1,&--2,&--3{
                    transition:all $zaux-transition-0;
                    height:1px;
                    display:block;
                    position:absolute;
                    width:100%;
                }
                &--1{
                    top:1px;
                }
                &--2{
                    top:50%;
                    transform:translateY(-50%);
                    width:80%;
                }
                &--3{
                    bottom:0;
                }
            }
        }
        &.active, [class*=--active], &.is-active{
            transform:rotate(45deg);
            .#{$component}__hamburger-line{
                &--1{
                    transform: rotate(90deg);
                    bottom: 0;
                    top: tokenSpacer(3);
                }
                &--2{
                    top:50%;
                    transform:translateY(-50%);
                    opacity:0;
                    width:100%;
                }
                &--3{
                    top:50%;
                    transform:translateY(-50%);
                }
            }
        }
    }

    &__language-selector{
        min-width:48px;
    }
}
