@charset "UTF-8";

$component: componentClass("indexlist");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    &__list{
        li{
            border-bottom-color:tokenColor("set1","color4");
            border-bottom-style:solid;
        }
    }

    &--theme-alt2{
        .#{$component}__list{
            li{
                border-bottom-color:tokenColor("set1","white1");
                color:tokenColor("set1","white1");
            }
        }
    }
}