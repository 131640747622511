@charset "UTF-8";

$component: componentClass("section2");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","color6");
    color:tokenColor("set1","color3");
    &__title{
        color:tokenColor("set1","color1");
    }
    &--theme{
        &-alt1{
            background-color:tokenColor("set1","color1");
            color:tokenColor("set1","white1");
            .#{$component}__title{
                color:tokenColor("set1","white1");
            }
        }
        &-alt2{
            background-color:tokenColor("set1","white1");
        }
    }
}