@charset "UTF-8";

$component: componentClass("section1");
$cmp-tokens: ();

.#{$component} {
    min-height:100vh;
    width:100vw;
    position:relative;
    &:before{
        position:absolute;
        left:50%;
        transform:translateX(-50%) rotate(180deg);
        content:"";
        border-width: 0 tokenSpacer(7) tokenSpacer(7) 0;
        top:-#{tokenSpacer(7)};
        z-index:tokenZindex(2);
    }

    &__inner{
        min-height:inherit;
        height:inherit;
    }

    @include media-breakpoint-down("md"){
        min-height:470px;
        &:before{
            top:-#{tokenSpacer(5)};
            border-width: 0 tokenSpacer(5) tokenSpacer(5) 0;
        }
    }

}