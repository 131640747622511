@charset "UTF-8";

$component: componentClass("showcase1");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{

    background-color:tokenColor("set1","color1");
    color:tokenColor("set1","white1");

    .#{$component}__slider{
        .#{$component}__slider-arrow{
            &--prev,&--next{
                .#{$component-prefix}icon{
                    color: tokenColor("set1","white1");
                }
            }
        }    
    }
    
    &__boxwrap{
        background-color:tokenColor("set1","white1");
    }

    &--theme{
        &-alt1{
            background-color:tokenColor("set1","color6");
            color:tokenColor("set1","color1");
            .#{$component}__eyelet{
                color: tokenColor("set1", "color3");
            }
            .#{$component}__subtitle{
                color: tokenColor("set1", "color3");
            }
            .#{$component}__slider{
                .#{$component}__slider-arrow{
                    &--prev,&--next{
                        .#{$component-prefix}icon{
                            color: tokenColor("set1","color1");
                        }
                    }
                }    
            }
        }
        &-alt2{
            background-color:tokenColor("set1","white1");
            color:tokenColor("set1","color3");
            .#{$component}__eyelet{
                color: tokenColor("set1", "color3");
            }
            .#{$component}__title{
                color:tokenColor("set1","color1");
            }
            .#{$component}__subtitle{
                color: tokenColor("set1", "color3");
            }
            .#{$component}__slider{
                .#{$component}__slider-arrow{
                    &--prev,&--next{
                        .#{$component-prefix}icon{
                            color: tokenColor("set1","color1");
                        }
                    }
                }    
            }
        }
    }

}