@charset "UTF-8";

$component: componentClass("cardwide2");
$cmp-tokens: ();

.#{$component} {
    &__content{
        position:relative;
        &:before{
            content:"";
            top:tokenSpacer(10);
            border-width: 0 tokenSpacer(9) tokenSpacer(9) 0;
            left:-#{tokenSpacer(9)-0.2};
            position:absolute;
            @include media-breakpoint-down("md"){
                top:-#{tokenSpacer(7)};
                left:auto;
                transform:rotate(90deg);
                right:#{tokenSpacer(8)};
            }
        }
    }

    &--flipped{
        .#{$component}__content{
            &:before{
                left:auto;
                right:-#{tokenSpacer(7)-0.2};
                transform:rotate(-90deg);
            }
        }
    }
}