@charset "UTF-8";

$component: componentClass("relatedstrip");
$cmp-tokens: ();

.#{$component}{
    &__graphic{
        @include media-breakpoint-down(md){
            width: tokenIconSize(5);
        }
    }
}