@charset "UTF-8";

$component: componentClass("loader");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    &__icon{
        color: tokenColor("set1","color1");
        svg{
            filter: drop-shadow(tokenShadow("sm"));
        }
    }
    &__dots{
        div{
            background-color:tokenColor("set1","color5");
        }
    }
}