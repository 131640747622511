@charset "UTF-8";

$component: componentClass("navbar1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:tokenColor("set1","color6");
    &__list{
        .#{$component}__list-item{
            &[class*=--active]{
                color:tokenColor("set1","color1");
            }
        }
    }
    &__scroll{
        &:before{
            background: linear-gradient(90deg, rgba(tokenColor("set1","color6"), 0.8) 30%, rgba(255,255,255,0) 80%);
        }
        &:after{
            background: linear-gradient(260deg, rgba(tokenColor("set1","color6"), 0.8) 30%, rgba(255,255,255,0) 80%);
        }
        &:before, &:after{
            @include media-breakpoint-down("md"){
                display: none;
            }
        }
    }
    &__btn{
        color:tokenColor("set1","color1");
    }
}