$component: componentClass("iconbox2");
$cmp-tokens: ();

.#{$component}{
    &--type1{
        max-width:166px;
        min-width:130px;
    }
    &__head{
        border-bottom-width:1px;
    }
    &--type2{
        height:100%;
        .#{$component}__inner{
            height:100%;
        }
        .#{$component}__head{
            height:100%;
        }
    }
}