@charset "UTF-8";

$component: componentClass("hero1");
$cmp-tokens: ();

@mixin hero1Res1{
    max-height:580px;
    .#{$component}__slider{
        max-height:580px;
    }
    .#{$component-prefix}slider__wrapper{
        max-height:580px;
    }
}

.#{$component} {

    &--fullview{
        height:100vh;
        width:100vw;
        .#{$component}__slider{
            height:100vh;
            width:100vw;
        }
        @include media-breakpoint-down("md"){
            @include hero1Res1;
        }
    }

    &__inner{
        height:100%;
        position:relative;
    }

    &__slider{
        .#{$component-prefix}slider{
            &__wrapper, &__instance{
                height:inherit;
            }
        }
    }

    &__media-bg{
        opacity:0.8;
        position:absolute;
        height:100%;
        width:100%;
        img{
            height:100%;
            width:100%;
        }
    }

    &__body{
        position:relative;
        z-index:tokenZindex(3);
    }

    &__pagination{
        position:absolute;
        width:auto !important;
        display:flex;
        flex-flow:column;
        left:auto !important;
        bottom:auto !important;
        top:tokenSpacer(13);
        right:tokenSpacer(10);
        z-index: tokenZindex(0);
        .swiper-pagination-bullet{
            margin-bottom:tokenSpacer(5) !important;
            border-radius:0;
        }
        @include media-breakpoint-down("md"){
            top:tokenSpacer(12);
            right:tokenSpacer(5);
        }
    }

    &--maxh1{
        @include hero1Res1;
    }
    
}