@charset "UTF-8";

$component: componentClass("articlemeta");
$cmp-tokens: ();

.#{$component} {
    &__attachments{
        li{
            border-width:1px;
        }
    }
}