@charset "UTF-8";

$component: componentClass("footer");
$cmp-tokens: ();

.#{$component} {
    @include c-typo-style("text-s", map-get($zaux-styles-typography, $style-name)); 
    &__row{
        &--2{
            border-top-width:1px;
        }
    }
    &__logo{
        @include media-breakpoint-down(md){
            img{
                max-width:152px;
            }
        }
    }
    &__links{
        li{
            &:last-child{
                margin-bottom:0;
            }
        }
    }
}
