@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    
    

    &__row{
        &--1{
            box-shadow:tokenShadow("sm");
            background-color:tokenColor("set1","color1");
            color:tokenColor("set1","white1");
        }
        &--2{
            background-color:tokenColor("set1", "color6");
            input{
                background-color:transparent;
                border:none;
            }
        }
    }

    .#{$component}__logo{
        background-color:tokenColor("set1","white1");
        position:relative;
        &:after{
            @include comicFlap(tokenColor("set1","white1"));
        }
    }
    &__motto{
        text-transform:uppercase;
        font-weight:$zaux-typo-fw-bold;
    }
    &__controls{
        .#{$component-prefix}icon{
            color:tokenColor("set1","white1");
        }
    }
    &__language-selector{
        background-color: transparent;
        border:none;
        font-weight:$zaux-typo-fw-bold;
        color:tokenColor("set1","white1");
        $select-arrow-color: cmpToken($cmp-tokens, "color-selectarrow", tokenColor("set1","white1"), "theme-light1" );
        $select-arrow-color: str-replace(#{$select-arrow-color}, "#", "%23");
        background-position:right center;
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#{$select-arrow-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
    &__hamburger{
        .#{$component}__hamburger-line{
            &--1,&--2,&--3{
                background-color:tokenColor("set1","white1");
            }
        }
    }
}