@charset "UTF-8";

$component: componentClass("authorsection");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component} {

    background-color:tokenColor("set1", "color1");
    color:tokenColor("set1", "white1");

    &--has-flap{
        &:before{
            @include comicFlap(tokenColor("set1","color1"));
            @include media-breakpoint-down("md"){
                top:-#{tokenSpacer(5)};
                border-width: 0 tokenSpacer(5) tokenSpacer(5) 0;
            }
        }
    }

    &--theme2{
        background-color:tokenColor("set1","white1");
        color:tokenColor("set1","color3");
        .#{$component}{
            &__title{
                color:tokenColor("set1","color1");
            }
        }
    }

}