@charset "UTF-8";

$component: componentClass("navbar1");
$cmp-tokens: ();

.#{$component} {
    .#{$component}__btn{
        display:none;
        z-index:tokenZindex(2);
    }
    &__scroll{
        position:relative;
        &:after, &:before{
            content:"";
            height:100%;
            position:absolute;
            width:tokenSpacer(12);
            top:0;
            pointer-events: none;
            @include media-breakpoint-down("md"){
                width:tokenSpacer(10);
            }
        }
        &:after{
            right:0;
        }
        &:before{
            left:0;
        }
    }
    &__track{
        overflow:hidden;
    }
    &[class*=--scrollable]{
        .#{$component}__track{
            overflow:scroll;
        }
        .#{$component}__scroll{
            position:relative;
            .#{$component}__btn{
                display:block;
                position:absolute;
                top: 50%;
                transform:translateY(-50%);
                &--prev{
                    left:0;
                    @include media-breakpoint-down("md"){
                        left:tokenSpacer(4);
                        display:none;
                    }
                }
                &--next{
                    right:0;
                    @include media-breakpoint-down("md"){
                        right:tokenSpacer(4);
                        display:none;
                    }
                }
            }
        }
    }
}