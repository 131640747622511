@charset "UTF-8";

$component: componentClass("contactbox1");
$cmp-tokens: ();

.#{$component} { 
    position:relative;
    &__glyph{
        position:absolute;
        left:50%;
        transform:translateX(-50%);
        top:-#{tokenSpacer(6)};
    }
    a{
        @include c-typo-style("link", map-get($zaux-styles-typography, "style1"), "layout");
    }
}