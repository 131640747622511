@charset "UTF-8";

$component: componentClass("breadcrumbs1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 .#{$component}{
    &:not([class*=--unthemed]){
        color:cmpToken($cmp-tokens, 'color-cmp', tokenColor('set1','color4'), 'theme-light1' );
        a{
            color:getCmpToken($cmp-tokens, 'color-cmp', tokenColor('set1','color4'), 'theme-light1' );
        }
    }
}