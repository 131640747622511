@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

@mixin form-field-theme-light1{
    border-style:none;
    appearance: none;

    //// FIELD TYPES ////

    //Default and type 1
    
        ///// GENERIC INPUT /////
        transition: background-color $zaux-transition-0, border-color $zaux-transition-0, box-shadow $zaux-transition-0;
        border-style:solid;
        border-width:1px;
        border-color: tokenColor("set1","color6");
        //border-bottom:1px solid cmpToken( $cmp-tokens, "color-fieldtype1-borderbottom", tokenColor("set1", "color5"), 'theme-light1');
        outline:none;
        color: cmpToken($cmp-tokens, 'color-inputtext', tokenColor(set1,black1), 'theme-light1');
        background-color:tokenColor("set1","color6");

        &:is(div){
            border-bottom:none;
        }
        
        &::placeholder{
            color:cmpToken($cmp-tokens, 'color-placeholder', tokenColor(set1,color-4), 'theme-light1');
        }
        &:after{
            transition: opacity $zaux-transition-0;
        }

        //Alternative theme
        &[class*=theme-alt2]{
            background-color:tokenColor("set1","white1");
        }

        &[class*=theme-alt3]{
            background-color:transparent;
            color:tokenColor("set1","white1");
            border-top-width:0px;
            border-left-width:0px;
            border-right-width:0px;
            &::placeholder{
                color:rgba(tokenColor("set1","white1"), 0.5);
            }
            & + [class*=__field-icon]{
                color:tokenColor("set1","white1");
            }
        }

        ///// SELECT /////

        &:is(select){
            $select-arrow-color: cmpToken($cmp-tokens, "color-selectarrow", tokenColor("set1","black1"), "theme-light1" );
            $select-arrow-color: str-replace(#{$select-arrow-color}, "#", "%23");
            background-position:right center;
            background-repeat:no-repeat;
            background-size:tokenSpacer(4) tokenSpacer(3);
            border-radius:0;
            background-position:right tokenSpacer(3) center;
            background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#{$select-arrow-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            &[class*=--size-s]{ background-size:tokenSpacer(4) tokenSpacer(3); }
            &[class*=--size-m]{ background-size:tokenSpacer(4) tokenSpacer(3); }
            &[class*=--size-l]{ background-size:tokenSpacer(4) tokenSpacer(4); }

            &[class*=theme-alt3]{
                $select-arrow-color: cmpToken($cmp-tokens, "color-selectarrow", tokenColor("set1","white1"), "theme-light1" );
                $select-arrow-color: str-replace(#{$select-arrow-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#{$select-arrow-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            }
        }

        ///// RADIOS /////

        &:is(input[type=radio]){
            //border-style:solid;
            //border-color: cmpToken($cmp-tokens, 'color-radioborder', tokenColor('set1', 'black1'), 'theme-light1' );
            border-radius:tokenRadius("full");
            &:after{
                background-color:cmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','color1'), 'theme-light1');
                border-radius:tokenRadius("full");
            }
            &:focus{
                //border-color: cmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                box-shadow: 0px 0px 0px 4px rgba(cmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                //border-style:solid;
                //border-color: cmpToken($cmp-tokens, 'color-radioborder_checked', tokenColor('set1', 'color1'), 'theme-light1' );
            }

            & + .#{$component}__field-label{
                a{
                    text-decoration:underline;
                }
            }

            &[class*=theme-alt2]{
                background-color:tokenColor("set1","color1");
                border:1px solid tokenColor("set1","white1");
                &:after{
                    background-color:cmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','white1'), 'theme-light1');
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","white1");
                }
            }

            &[class*=theme-alt3]{
                background-color:tokenColor("set1","white1");
                border:1px solid tokenColor("set1","white1");
                &:after{
                    background-color:cmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','color1'), 'theme-light1');
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","color3");
                }
            }
            &[class*=theme-alt4]{
                background-color:tokenColor("set1","white1");
                border:1px solid tokenColor("set1","white1");
                &:after{
                    background-color:cmpToken($cmp-tokens, 'color-radiodot', tokenColor('set1','color1'), 'theme-light1');
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","white1");
                }
            }
        }

        ///// CHECKBOXES /////

        &:is(input[type=checkbox]){
            //border-style:solid;
            //border-color: cmpToken($cmp-tokens, 'color-checkboxborder', tokenColor('set1', 'black1'), 'theme-light1' );
            border-radius:tokenRadius(0);
            &:focus{
                //border-color: cmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                box-shadow: 0px 0px 0px 3px rgba(cmpToken($cmp-tokens, 'color-radioborder_focus', tokenColor('set1', 'color1'), 'theme-light1' ), 0.2);
            }
            &:checked{
                background-color:cmpToken($cmp-tokens, 'color-checkboxbg_focus', tokenColor('set1','color1'), 'theme-light1');
                //border-color: cmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                $checkbox-checkmark-color: cmpToken($cmp-tokens, 'color-checkmark', tokenColor('set1','white1'), 'theme-light1' );
                $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
            }

            & + .#{$component}__field-label{
                a{
                    text-decoration:underline;
                }
            }

            
            &[class*=theme-alt2]{
                background-color:transparent;
                border:1px solid tokenColor("set1","white1");
                &:checked{
                //border-color: cmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                background-color:tokenColor("set1","color1");
                $checkbox-checkmark-color: tokenColor('set1','white1');
                $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","white1");
                }
                & + [class*=#{$component}__validation-label]{
                    color:tokenColor("set1","white1");
                }
            }

            &[class*=theme-alt3]{
                background-color:tokenColor('set1','white1');
                &:checked{
                //border-color: cmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                $checkbox-checkmark-color: tokenColor('set1','color1');
                $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","color3");
                }
            }
            &[class*=theme-alt4]{
                background-color:tokenColor("set1","white1");
                border:1px solid tokenColor("set1","white1");
                &:checked{
                    //border-color: cmpToken($cmp-tokens, 'color-checkboxborder_focus', tokenColor('set1', 'color1'), 'theme-light1' );
                    $checkbox-checkmark-color: tokenColor('set1','color1');
                    $checkbox-checkmark-color: str-replace(#{$checkbox-checkmark-color}, "#", "%23");
                    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#{$checkbox-checkmark-color}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
                }
                & + .#{$component}__field-label{
                    color: tokenColor("set1","white1");
                }
                & + [class*=#{$component}__validation-label]{
                    color:tokenColor("set1","white1");
                }
            }
        }
        
        //// FILES ////

        &:is(input[type=file]){
            background-color:transparent;
            border-color:transparent;

            &::file-selector-button{
                appearance:none;
                background-color:tokenColor("set1","color1");
                color:tokenColor("set1","white1");
                text-transform:uppercase;
                font-weight: $zaux-typo-fw-medium;
                transition: background-color $zaux-transition-1;
                //@extend .c-btn, .c-btn--primary, .c-btn--size-s;
                &:hover{
                    background-color:lighten(tokenColor("set1","color1"), 15%);
                }
                &:active{
                    background-color:darken(tokenColor("set1","color1"), 15%);
                }
            }

            &[class*=theme-alt2]{
                color:tokenColor("set1","color3");
                &::file-selector-button{
                    background-color:tokenColor("set1","white1");
                    color:tokenColor("set1","color3");
                }
            }

            &[class*=theme-alt3]{
                color:tokenColor("set1","white1");
                &::file-selector-button{
                    background-color:transparent;
                    border:1px solid tokenColor("set1","white1");
                    color:tokenColor("set1","white1");
                }
            }

        }

    /// LABELS ///
    
    &-label{
        color:cmpToken($cmp-tokens, 'color-fieldlabel', tokenColor(set1,black1), 'theme-light1');
    }

    /// ICONS ///
    /// 
    &-icon{
        color:cmpToken($cmp-tokens, 'color-fieldicondf', tokenColor("set1","black1"), 'theme-light1');
    }

}
@mixin form-field-status-theme-light1($status){
    @if $status == "success" {
        border-color:tokenColor("default", "success");
        &:is([type=checkbox]), &:is([type=radio]){
            border-color:tokenColor("set1", "color1");
        }
        //color:tokenColor("default","success");
        &::placeholder{
            //color:tokenColor("default", "success");
        }   
        & + [class*=#{$component}__validation-label]{
            color:tokenColor("default","success");
        }
    }
    @if $status == "warning" {
        border-color:tokenColor("default", "warning");
        color:tokenColor("default","warning");
        &::placeholder{
            color:tokenColor("default", "warning");
        }   
    }
    @if $status == "error"{
        border-color:tokenColor("set1","color1");
        color:tokenColor("set1","color1");
        &::placeholder{
            color:tokenColor("set1","color1");
        }   
        & + [class*=#{$component}__validation-label]{
            color:tokenColor("set1","color1");
        }
    }
}
.zaux-theme--light1, .zaux-theme--light1 .#{$component}{

    &__field,input,textarea,select{
        @include form-field-theme-light1;
    }

    .#{$component}__field{
        &--error{ @include form-field-status-theme-light1("error")}
        &--warning{ @include form-field-status-theme-light1("warning") }
        &--success{ @include form-field-status-theme-light1("success") }
    }

    &__field-wrap{
        &--success{ .#{$component}__field{ @include form-field-status-theme-light1("success") } }
        &--warning{ .#{$component}__field{ @include form-field-status-theme-light1("warning") } }
        &--error{ .#{$component}__field{ @include form-field-status-theme-light1("error") } }
    }

    &__loader{
        border-color:tokenColor("set1","color1");
        border-style:solid;
        border-right-color:transparent;
        &--theme2{
            border-color:tokenColor("set1","white1");
            border-right-color:transparent;
        }
    }

    &__validation-pot{
        &--theme2{
            [class*=#{$component}__validation-label]{
                color:tokenColor("set1","white1") !important;
            }
        }
    }

}