@charset "UTF-8";

$component: componentClass("sliderstrip1");
$cmp-tokens: ();

.#{$component}{
    &__slide{
        max-height:650px;
    }
    &--nomaxh{
        .#{$component}__slide{
            max-height:none;
        }
    }
}