@charset "UTF-8";

$component: componentClass("btn");
$cmp-tokens: ();

@mixin c-btn-size($px) {
  text-align: center;

  .#{$component} {
    &__inner {
      font-size: rem($px);
      border: 1px solid transparent;
      height: 1em;
      border-radius: 0.5em;
      display: block;
    }
  }
}

[class*="#{$component}--"] {
  // Commented in favor of Bootstrap's `$line-height-base: 0;` (see src/assets/scss/abstracts/_variables.scss)
  //font-size: 0;
  //line-height: 0;

  &,
  &:link,
  &:visited,
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.#{$component} {

  display:inline-block;
  text-transform:uppercase;
  font-weight: cmpToken($cmp-tokens, 'fw-btn', $zaux-typo-fw-regular, 'layout');
  letter-spacing: cmpToken($cmp-tokens, 'ls-btn', rem(tokenLetterSpacing(1)), 'layout');
  text-align:center;
  transition: all $zaux-transition-1;

  padding: cmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(2), 'layout') cmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
  //font-size: cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(3), 'layout');
  line-height: cmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(3), 'layout');

  .#{$component}__label{
    font-size:cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
  }

  //Small
  &--size-s{
    padding: cmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(1), 'layout') cmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(4), 'layout');
    font-size: cmpToken($cmp-tokens, 'fs-btnsmall', tokenTypoSizeFs(1), 'layout');
    line-height: cmpToken($cmp-tokens, 'lh-btnsmall', tokenTypoSizeLh(3), 'layout');
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
    }
    .#{$component}__icon{
      margin-right: cmpToken($cmp-tokens, 'spacer-iconmd', tokenSpacer(2), 'layout');
    }
    &:after{
      border-width: 0 18px 21px 0;
      right:-18px;
      top:53%;
      transform:translateY(-50%) rotate(270deg);
    }
  }

  //Default and medium
  &--size-m, &:not([class*=--size]){
    padding: cmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(2), 'layout') cmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
    font-size: cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
    line-height: cmpToken($cmp-tokens, 'lh-btnmedium', tokenTypoSizeLh(3), 'layout');
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnmedium', tokenTypoSizeFs(1), 'layout');
    }
    .#{$component}__icon{
      margin-right: cmpToken($cmp-tokens, 'spacer-iconmd', tokenSpacer(2), 'layout');
    }
    &:after{
      border-width: 0 21px 24px 0;
      right:-21px;
      top:53%;
      transform:translateY(-50%) rotate(270deg);
    }
  }

  //Large
  &--size-l{
    padding: cmpToken($cmp-tokens, 'spacer-btnmedium-py', tokenSpacer(5), 'layout') cmpToken($cmp-tokens, 'spacer-btnmedium-px', tokenSpacer(6), 'layout');
    font-size: cmpToken($cmp-tokens, 'fs-btnlarge', tokenTypoSizeFs(3), 'layout');
    line-height: cmpToken($cmp-tokens, 'lh-btnlarge', tokenTypoSizeLh(2), 'layout');
    .#{$component}__label{
      font-size:cmpToken($cmp-tokens, 'fs-btnlarge', tokenTypoSizeFs(3), 'layout');
    }
    .#{$component}__icon{
      margin-right: cmpToken($cmp-tokens, 'spacer-iconlg', tokenSpacer(2), 'layout');
    }
    &:after{
      right:-34px;
      top:50%;
      transform:translateY(-50%) rotate(270deg);
      border-width: 0 34px 40px 0;
    }
  }

  //Responsive 
  &--res1{
    @include media-breakpoint-down("sm"){
      &[class*=--size-l]{
        padding:tokenSpacer(3) tokenSpacer(4);
        font-size: tokenTypoSizeFs(2);
        .#{$component}__label{
          font-size:tokenTypoSizeFs(2);
        }
        &:after{
          right:-21px;
          top:50%;
          transform:translateY(-50%) rotate(270deg);
          border-width: 0 21px 24px 0;
        }
      }
    }
  }

}
