@charset "UTF-8";

$component: componentClass("picturecard1");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{

    background-color:tokenColor("set1","black1");
    color:tokenColor("set1","white1");

    &__content{
        background-color:rgba( tokenColor("set1","black1"), 0.4 );
        transition: background-color $zaux-transition-1;
    }

    &:hover{
        .#{$component}__content{
            background-color:rgba( tokenColor("set1","black1"), 0.2 );
        }
    }

}