@charset "UTF-8";

$component: componentClass("indexblock");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","white1");
    color:tokenColor("set1","color4");
    &__title{
        color:tokenColor("set1","color1");
    }
    &__list{
        &-title{
            color:tokenColor("set1","color1");
        }
        li{
            &:not(:first-child){
                list-style-type: none;
                &:before {
                    content: "•";
                }
            }
        }
    }
    &--has-flap{
        &:before{
            @include comicFlap(tokenColor("set1","white1"));
            @include media-breakpoint-down("md"){
                top:-#{tokenSpacer(5)};
                border-width: 0 tokenSpacer(5) tokenSpacer(5) 0;
            }
        }
    }
}