$zaux-token-typo: (
  base-font-size: 16px,
  breakpoints: (sm, md, lg, xl),
  sizes: (
    0: 0px,
    1: 12px,
    2: 14px,
    3: 18px,
    4: 24px,
    5: 30px,
    6: 32px,
    7: 36px,
    8: 52px,
    9: 66px,
    10: 96px
  ),
  lineHeights: (
    0: 0px,
    1: 16px,
    2: 18px,
    3: 20px,
    4: 28px,
    5: 30px,
    6: 32px,
    7: 34px,
    8: 36px,
    9: 40px,
    10: 58px,
    11: 72px,
    12: 100px
  ),
  letterSpacing: (
    0: 0px,
    1: 1px,
    2: 8px
  )
);
