@charset "UTF-8";

$component: componentClass("card1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{

    border-bottom-color:tokenColor("set1","color3");
    border-bottom-style:solid;
    &__title{
        color:tokenColor("set1","color1");
    }
}