@charset "UTF-8";

$component: componentClass("mediablock");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","black1");
    .#{$component}{
        &__media{
            opacity:0.75;
        }
    }
    &--opaque{
        .#{$component}__media{
            opacity:1;
        }
    }
    &--transparent, &--contained{
        background-color:transparent;
        .#{$component}__media{
            opacity:1;
        }
    }
}