@charset "UTF-8";

$component: componentClass("toast");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{


    background:linear-gradient(0deg, rgba(tokenColor("set1","black1"), 0.2) 0%, rgba(tokenColor("set1","black1"), 0.1) 30%, rgba(tokenColor("set1","white1"), 0) 80%);

    &__content{
        background-color: tokenColor("set1","color1");
        color:tokenColor("set1","white1");
        box-shadow:tokenShadow("md");
        position:relative;
        &:after{
            @include comicFlap(tokenColor("set1","color1"));
        }
    }

    &--theme-success{
        .#{$component}__content{
            background-color: tokenColor("default","success");
            &:after{
                @include comicFlap(tokenColor("default","success"));
            }
        }
    }

    &--theme-error{
        .#{$component}__content{
            background-color:tokenColor("default","error");
            &:after{
                @include comicFlap(tokenColor("default","error"));
            }
        }
    }

    &--theme-blank{
        .#{$component}__content{
            box-shadow:none;
            background-color:transparent;
            &:after{
                display:none;
            }
        }
    }

    &[class*=--show]{
        background:linear-gradient(0deg, rgba(tokenColor("set1","black1"), 0.2) 0%, rgba(tokenColor("set1","black1"), 0.1) 30%, rgba(tokenColor("set1","white1"), 0) 80%);
    }

}