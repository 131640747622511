@charset "UTF-8";

$component: componentClass("bottombar");
$cmp-tokens: ();

.#{$component}{
    position:fixed;
    z-index:tokenZindex(5);
    bottom:tokenSpacer(10);
    right:tokenSpacer(10);

    //Ugly fix but it puts bread on the table
    [class*=btn]{
        [class*=__label]{
            opacity:0;
        }
        &:hover{
            [class*=__label]{
                opacity:1;
            }
        }
    }

    @include media-breakpoint-down("md"){
        bottom:tokenSpacer(4);
        right:tokenSpacer(5);
    }

}