@charset "UTF-8";

// Common weight name mapping
// The numerical values 100 to 900 roughly correspond to the following
// common weight names (see the OpenType specification):
//
// @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
// @see https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass

// Value	Common weight name
// ------------------------------------------------------
// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
// 950	Extra Black (Ultra Black)
// ------------------------------------------------------

[class^="icozaux1-"],
[class*=" icozaux1-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

body{
    font-family:$zaux-font1;
}