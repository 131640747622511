$zaux-styles-typography: (
  style1: ((
    name: title-xxl,
    fontFamily: "Bw Modelica",
    fontSize: 96px,
    fontWeight: 800,
    lineHeight: 100px,
    color: #CD2D45
  ), (
    name: title-xl,
    fontFamily: "Bw Modelica",
    fontSize: 66px,
    fontWeight: 800,
    lineHeight: 72px,
    color: #CD2D45
  ), (
    name: title-l,
    fontFamily: "Bw Modelica",
    fontSize: 52px,
    fontWeight: 800,
    lineHeight: 58px,
    color: #CD2D45
  ), (
    name: title-m,
    fontFamily: "Bw Modelica",
    fontSize: 36px,
    fontWeight: 800,
    lineHeight: 40px,
    color: #CD2D45
  ), (
    name: title-s,
    fontFamily: "Bw Modelica",
    fontSize: 30px,
    fontWeight: 800,
    lineHeight: 32px,
    color: #CD2D45
  ), (
    name: title-xs,
    fontFamily: "Bw Modelica",
    fontSize: 24px,
    fontWeight: 800,
    lineHeight: 28px,
    color: #CD2D45
  ), (
    name: title-xxs,
    fontFamily: "Bw Modelica",
    fontSize: 18px,
    fontWeight: 800,
    lineHeight: 20px,
    color: #CD2D45
  ), (
    name: heading-m,
    fontFamily: "Bw Modelica",
    fontSize: 14px,
    fontWeight: 400,
    lineHeight: 34px,
    letterSpacing: 8px,
    textTransform: uppercase,
    color: #363636,
    lineCapFix: 8
  ), (
    name: heading-s,
    fontFamily: "Bw Modelica",
    fontSize: 12px,
    fontWeight: 400,
    lineHeight: 32px,
    letterSpacing: 8px,
    textTransform: uppercase,
    color: #363636,
    lineCapFix: 8
  ), (
    name: text-xl,
    fontFamily: "Bw Modelica",
    fontSize: 32px,
    fontWeight: 400,
    lineHeight: 36px,
    color: #363636
  ), (
    name: text-l,
    fontFamily: "Bw Modelica",
    fontSize: 24px,
    fontWeight: 400,
    lineHeight: 30px,
    color: #363636
  ), (
    name: text-m,
    fontFamily: "Bw Modelica",
    fontSize: 18px,
    fontWeight: 400,
    lineHeight: 28px,
    color: #363636
  ), (
    name: text-s,
    fontFamily: "Bw Modelica",
    fontSize: 14px,
    fontWeight: 400,
    lineHeight: 20px,
    color: #363636
  ), (
    name: text-xs,
    fontFamily: "Bw Modelica",
    fontSize: 12px,
    fontWeight: 400,
    lineHeight: 18px,
    color: #363636
  ), (
    name: label,
    fontFamily: "Bw Modelica",
    fontSize: 12px,
    fontWeight: 400,
    lineHeight: 18px,
    color: #363636
  ), (
    name: link,
    fontFamily: "Bw Modelica",
    fontSize: 12px,
    fontWeight: 400,
    lineHeight: 18px,
    color: #CD2D45,
    textDecoration: underline,
    cursor: pointer
  ))
);
