@charset "UTF-8";

//@import "../../assets/scss/components/tokens/btn.style";

$component: componentClass("btn2");
$cmp-tokens: ();

/*
@if ($zaux-btn-style-tokens){
    $cmp-tokens : $zaux-btn-style-tokens;
}
*/

$c-btn2-hover-color-shift-factor: 10%;

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component} {
    font-weight:$zaux-typo-fw-medium;

    .#{$component}__glyph{
        path{
            transition:fill $zaux-transition-1;
            fill:cmpToken($cmp-tokens, "color-glyph", tokenColor("set1","color1"), "theme-light1");
        }
    }

    .#{$component}__label{
        color:cmpToken($cmp-tokens, "color-label", tokenColor("set1", "color5"), "theme-light1");
        transition: all $zaux-transition-1;
    }

    &:hover{
        .#{$component}__glyph{
            path{
                fill:lighten(cmpToken($cmp-tokens, "color-glyph", tokenColor("set1","color1"), "theme-light1"), $c-btn2-hover-color-shift-factor);
            }
        }
    }

    &:active,&.active{
        .#{$component}__glyph{
            path{
                fill:darken(cmpToken($cmp-tokens, "color-glyph", tokenColor("set1","color1"), "theme-light1"), $c-btn2-hover-color-shift-factor);
            }
        }
    }

    &--light{
        .#{$component}__label{
            color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "white1"), "theme-light1");
            &:hover, &:active, &.active{
                opacity:0.8;
                color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "white1"), "theme-light1");
            }
        }   
    }

    &--light2{
        &:hover, &:active, &.active{
            .#{$component}__glyph{
                path{
                    fill:cmpToken($cmp-tokens, "color-glyph", tokenColor("set1","white1"), "theme-light1");
                }
            }
        }
        .#{$component}__glyph{
            path{
                fill:cmpToken($cmp-tokens, "color-glyph", tokenColor("set1","white1"), "theme-light1");
            }
        }
        .#{$component}__label{
            color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "white1"), "theme-light1");
            &:hover, &:active, &.active{
                opacity:0.8;
                color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "white1"), "theme-light1");
            }
        }   
    }

    &--theme2{
        .#{$component}__label{
            color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "color1"), "theme-light1");
            &:hover, &:active, &.active{
                opacity:0.8;
                color:cmpToken($cmp-tokens, "color-labellight", tokenColor("set1", "color1"), "theme-light1");
            }
        }  
    }

}