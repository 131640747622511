@charset "UTF-8";

$component: componentClass("contactbox1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","color6");
    a{
        @include c-typo-style("link", map-get($zaux-styles-typography, "style1"), "theme");
    }
}   