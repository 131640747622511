$zaux-token-colors: (
  default: (
    white: #FFFFFF,
    black: #333333,
    red: #d9534f,
    green: #5cb85c,
    blue: #337ab7,
    magenta: #FF00FF,
    orange: #f0ad4e,
    lightgray: #e8e8e8,
    warning: #f0ad4e,
    error: #d72e29,
    success: #11bf86
  ),
  app: (
    theme: #CD2D45
  ),
  set1: (
    black1: #000000,
    white1: #FFFFFF,
    color1: #CD2D45,
    color2: #74303B,
    color3: #363636,
    color4: #464646,
    color5: #747474,
    color6: #f2f2f2
  )
);
