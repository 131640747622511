@charset "UTF-8";

$component: componentClass("indexblock");
$cmp-tokens: ();

.#{$component} { 
    &--has-flap{
        position:relative;
        &:before{
            position:absolute;
            left:50%;
            transform:translateX(-50%) rotate(180deg);
            content:"";
            border-width: 0 tokenSpacer(7) tokenSpacer(7) 0;
            top:-#{tokenSpacer(7)};
            z-index:tokenZindex(2);
        }
    }
    &__list{
        li{
            &:not(:first-child){
                list-style-type: none;
                &:before {
                    content: "•";
                    float: left;
                    margin-right: tokenSpacer(3);
                    font-size: tokenTypoSizeFs(3);
                }
            }
        }
    }
}   