@charset "UTF-8";

$component: componentClass("relatedstrip2");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","color6");
    color:tokenColor("set1","color3");
    &__title{
        color:tokenColor("set1","color1");
    }
    &--theme2{
        background-color:tokenColor("set1","white1");
    }
    
}