@charset "UTF-8";

//@import "../../assets/scss/components/tokens/img.style";

$component: componentClass("img");
$cmp-tokens: ();

@if (variable-exists(zaux-img-style-tokens)){
    $cmp-tokens : $zaux-img-style-tokens;
}

.#{$component} {

    //Defaults
    img{
        object-fit:cover;
        max-height:100%;
        max-width:100%;
    }
    
    &--fluid{
        img{
            width:100%;
        }
    }

    &--fluid-y{
        img{
            height:100%;
        }
    }

    &--fluid-xy{
        img{
            height:100%;
            width:100%;
        }
    }

    &--hauto{
        img{
            height:auto;
        }
    }

    &--wauto{
        img{
            width:auto;
        }
    }
    
    &--fit-cover{
        img{
            object-fit:cover;
        }
    }

    &--fit-none{
        img{
            object-fit:none;
        }
    }

    &--fit-contain{
        img{
            object-fit:contain;
        }
    }

    &--fit-scale-down{
        img{
            object-fit: scale-down;
        }
    }

    &--fit-fill{
        img{
            object-fit:fill;
        }
    }

    //Ratios
    @each $ratioIndex, $ratio in $zaux-token-ratios{
        &--ratio-#{$ratioIndex}{
            img{
                $aspRatio : #{map-get($ratio, "ratioEscaped")};
                aspect-ratio: $aspRatio;
            }
        }
    }

    @each $ratioIndex, $ratio in $zaux-token-ratios{
        @each $bpName, $bpVal in tokenBreakpoints(){
            @include media-breakpoint-up($bpName){
                &--ratio-#{$bpName}-#{$ratioIndex}{
                    img{
                        $aspRatio : #{map-get($ratio, "ratioEscaped")};
                        aspect-ratio: $aspRatio;
                    }
                }
                &--#{$bpName}-fluid{
                    img{
                        width:100%;
                    }
                }
                &--#{$bpName}-fluid-y{
                    img{
                        height:100%;
                    }
                }
                &--#{$bpName}-wauto{
                    img{
                        width:auto;
                    }
                }
                &--#{$bpName}-hauto{
                    img{
                        height:auto;
                    }
                }
                &--#{$bpName}-fit-cover{
                    img{
                        object-fit:cover;
                    }
                }
                &--#{$bpName}-fit-contain{
                    img{
                        object-fit:contain;
                    }
                }
                &--#{$bpName}-fit-none{
                    img{
                        object-fit:none;
                    }
                }
                &--#{$bpName}-fit-scale-down{
                    img{
                        object-fit:scale-down;
                    }
                }
                &--#{$bpName}-fit-fill{
                    img{
                        object-fit:fill;
                    }
                }
            }
        }
    }

}