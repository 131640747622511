$attribute-prefix : tokenDef("app-prefix");

[data-#{$attribute-prefix}-tab-content]{
    display:none;
    opacity:0;
    &:not(.--fade), &:not(.fade){
        display:none;
        &.--active, &.active{
            display:block;
        }
    }
    &.--fade, &.fade{
        opacity:0;
        transition: opacity $zaux-transition-1;
        &.--active, &.active{
            display:block;
        }
        &.--show, &.show{
            opacity:1;
        }
    }
}

[data-#{$attribute-prefix}-tab]{
}