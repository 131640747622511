@charset "UTF-8";

$component: componentClass("iconbox");
$cmp-tokens: ();

$hover-factor: 15%;

.zaux-theme--#{$zaux-theme-light1} .#{$component}{

    $bgcolor : cmpToken($cmp-tokens, "color-bg", tokenColor("set1","color1"), "theme-light1");
    $textcolor : cmpToken($cmp-tokens, "color-text", tokenColor("set1","white1"), "theme-light1" );
    background-color:$bgcolor;
    color: $textcolor;
    position:relative;
    transition: background-color $zaux-transition-0;

    &:after{
        @include comicFlap($bgcolor);
        content:"";
        transition: border-color $zaux-transition-0;
    }

    &--hoverable{
       
        &:hover{
            background-color:lighten($bgcolor, $hover-factor);
            &:after{
                @include comicFlap(lighten($bgcolor, $hover-factor));
            }
        }
        &:active{
            background-color:darken($bgcolor, $hover-factor);
            &:after{
                @include comicFlap(darken($bgcolor, $hover-factor));
            }
        }
    }

    &__text{
        text-transform:uppercase;
    }

    &--switch1{
        $hover-factor: 2%;
        background-color:$textcolor;
        color: $bgcolor;   
        &:after{
            @include comicFlap($textcolor);
        }
        &[class*=--hoverable]{
            &:hover{
                background-color:darken($textcolor, $hover-factor);
                &:after{
                    @include comicFlap(darken($textcolor, $hover-factor));
                }
            }
            &:active{
                background-color:darken($textcolor, $hover-factor);
                &:after{
                    @include comicFlap(darken($textcolor, $hover-factor));
                }
            }
        }
    }

}