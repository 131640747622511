@charset "UTF-8";

$component: componentClass("mosaic2");
$cmp-tokens: ();

.#{$component}{

    @include media-breakpoint-up("md"){
        height:100vh;
        overflow:hidden;
        &__inner{
            height:100%;
        }
        &__col--1{
            height:inherit;
        }
        &__col--2{
            height:inherit;
            .#{$component}__row{
                &--1{
                    height:50%;
                }
                &--2{
                    height:50%;
                }
            }
        }
    }
}