@charset "UTF-8";

$component: componentClass("offcanvasnav");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////


.zaux-theme--light1 .#{$component}{
    background-color:cmpToken($cmp-tokens, 'color-cmp-bg', tokenColor(set1,color1), 'theme-light1' );
    //box-shadow: cmpToken($cmp-tokens, "dropshadow-header", tokenShadow('sm') rgba(tokenColor('set1', 'black1'), 0.4), "theme-light1");
    color: cmpToken($cmp-tokens, "color-cmp-txtcolor", tokenColor(set1,white1),'theme-light1');
    &__menu{
        .#{$component}__list{
            li{
                transition: text-shadow $zaux-transition-1, transform $zaux-transition-1, opacity $zaux-transition-1;
                &:hover{
                    transform:translateX(tokenSpacer(3));
                    text-shadow:tokenShadow("sm");
                }
                &:active{
                    transform:scale(0.98);
                    opacity:0.8;
                }
            }
        }
    }
    .#{$component-prefix}icon{
        color: cmpToken($cmp-tokens, "color-cmp-txtcolor", tokenColor(set1,white1),'theme-light1');
    }
    &__language-selector{
        background-color: transparent;
        border:none;
        font-weight:$zaux-typo-fw-bold;
        color:tokenColor("set1","white1");
        $select-arrow-color: cmpToken($cmp-tokens, "color-selectarrow", tokenColor("set1","white1"), "theme-light1" );
        $select-arrow-color: str-replace(#{$select-arrow-color}, "#", "%23");
        background-position:right center;
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='#{$select-arrow-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
}