@charset "UTF-8";

$component: componentClass("badge");
$cmp-tokens: ();

.#{$component} {
    padding: tokenSpacer(2) tokenSpacer(3);
    @include c-typo-style("text-s", map-get($zaux-styles-typography, "style1"));
    &--size-m{
        padding: tokenSpacer(3) tokenSpacer(4);
        @include c-typo-style("text-m", map-get($zaux-styles-typography, "style1"));
    }
    &--size-l{
        padding: tokenSpacer(4) tokenSpacer(5);
        @include c-typo-style("text-l", map-get($zaux-styles-typography, "style1"));
    }
}