@charset "UTF-8";

$component: componentClass("indexlist");
$cmp-tokens: ();

.#{$component} { 

    &__list{
        li{
            border-bottom-width: 1px;
            position:relative;
            &:not(:first-child){
                &:before{
                    content:"•";
                    position:absolute;
                    top:50%;
                    left:0;
                    transform:translateY(-50%);
                }
            }
        }
    }

}