@charset "UTF-8";

$component: componentClass("section1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","color1");
    color:tokenColor("set1","white1");

    &:before{
        @include comicFlap(tokenColor("set1","color1"));
    }
    
}