@charset "UTF-8";

$component: componentClass("articlemeta");
$cmp-tokens: ();

.zaux-theme--#{$zaux-theme-light1} .#{$component} {
    color:tokenColor("set1","white1");
    background-color:tokenColor("set1","color1");
    li{
        &:first-child{
            border-top-color:tokenColor("set1","white1");
            border-top-style:solid;
        }
        border-bottom-color:tokenColor("set1","white1");
        border-bottom-style:solid;
    }
}