@charset "UTF-8";

$component: componentClass("contentblock1");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    &__title{
        color:tokenColor("set1","color1");
    }
}   