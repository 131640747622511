@charset "UTF-8";

$component: componentClass("heroslider");
$cmp-tokens: ();

@mixin heroSliderRes1{
    max-height:580px;
    .#{$component}__slider{
        max-height:580px;
    }
    .#{$component}__inner{
        max-height:580px;
    }
    .#{$component-prefix}slider__wrapper{
        max-height:580px;
    }
}

.#{$component} {

    &--fullview{
        height:100vh;
        width:100vw;
        .#{$component}__slider{
            height:100vh;
            width:100vw;
        }
        @include media-breakpoint-down("md"){
            @include heroSliderRes1;
        }
    }

    &__slide-inner{
        position:relative;
    }

    &__slider{
        .#{$component-prefix}slider{
            &__wrapper, &__instance{
                height:inherit;
            }
        }
    }

    &--nofullview{
        height:auto;
        width:auto;
        .#{$component}__slider{
            height:auto;
            width:auto;
        }
    }

    &__media-bg{
        opacity:0.8;
        position:absolute;
        height:100%;
        width:100%;
        img{
            height:100%;
            width:100%;
        }
    }

    &__body{
        position:relative;
        z-index:tokenZindex(3);
    }

    &__pagination{
        position:absolute;
        width:auto !important;
        display:flex;
        flex-flow:column;
        left:auto !important;
        bottom:auto !important;
        top:tokenSpacer(13);
        right:tokenSpacer(10);
        z-index: tokenZindex(0);
        .swiper-pagination-bullet{
            margin-bottom:tokenSpacer(5) !important;
            border-radius:0;
        }
        @include media-breakpoint-down("md"){
            display:flex;
            top:tokenSpacer(12);
            right:tokenSpacer(5);
            .swiper-pagination-bullet{
                margin-bottom:tokenSpacer(4) !important;
            }
        }
    }

    &--maxh1{
        @include heroSliderRes1;
    }

    &--ux-fadein {
      .c-slider__instance {
        &:not(.swiper-initialized) {
          background-color: tokenColor("set1","color3");
          .#{$component}__slide{
            background-color: transparent;
          }
          .#{$component}__slide-inner {
            opacity: 0;
          }
        }
        .#{$component}__slide-inner {
          transition-property: all;
          transition-duration: 0.250s;
          transition-timing-function: ease-in-out;
        }
      }
    }

}
