@charset "UTF-8";

$component: componentClass("casedevice");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    &__screen{
        .#{$component}__screen-frame{
            border-color:tokenColor("set1", "color1");
            img{
                border:1px solid tokenColor("set1","color1");
            }
        }
        .#{$component}__screen-stand{
            border:1px solid tokenColor("set1","color1");
            position:relative;
            top:-2px;
            border-bottom:none;
            background-color: tokenColor("set1","white1");
            //background-color: tokenColor("set1","color5");
            //background: linear-gradient(180deg, tokenColor("set1","color6") 80%, lighten(tokenColor("set1","color5"), 40%) 100%);
        }
        .#{$component}__screen-stand-bottom{
            background-color: tokenColor("set1","white1");
            border:1px solid tokenColor("set1","color1");
        }
    }
}   