@charset "UTF-8";

$component: componentClass("section4");
$cmp-tokens: ();

.#{$component} {
    &__text-content{
        width:100%;
        max-width: tokenContainer("l") / 2;
        @include media-breakpoint-down("md"){
            max-width:100%;
        }
    }
}