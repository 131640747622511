@charset "UTF-8";

$zaux-helper-name: "ratio";


.#{tokenDef("app-prefix")}-#{$zaux-helper-name} {

    @each $ratioIndex, $ratio in $zaux-token-ratios{
        &--#{$ratioIndex}{
            $aspRatio : #{map-get($ratio, "ratioEscaped")};
            aspect-ratio: $aspRatio;
        }
        @each $bpName, $bpVal in tokenBreakpoints(){
            @include media-breakpoint-up($bpName){
                &--#{$bpName}-#{$ratioIndex}{
                    $aspRatio : #{map-get($ratio, "ratioEscaped")};
                    aspect-ratio: $aspRatio !important;
                }
            }
        }
    }

}