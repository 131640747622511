@charset "UTF-8";

$component: componentClass("heroslider");
$cmp-tokens: ();

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--#{$zaux-theme-light1} .#{$component}{
    background-color:tokenColor("set1","black1");
    color:tokenColor("set1","white1");
    &__slide{
        background-color:tokenColor("set1","black1");
    }
}