@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.zaux-theme--light1 {

  $style-name: "style1";

  @each $typoStyle in map-get($zaux-styles-typography, $style-name) {
    .#{$component}-#{$style-name}-#{map-get($typoStyle, "name")}{
      @include c-typo-style(map-get($typoStyle, "name"), map-get($zaux-styles-typography, $style-name), "theme");
    }
  }

  [class*="#{$component}-block-#{$style-name}-themed"] {
    h1,
    h2
    {
      @include c-typo-style("title-xl", map-get($zaux-styles-typography, $style-name), "theme");
    }
    
    h3,h4,h5{
      @include c-typo-style("text-xl", map-get($zaux-styles-typography, $style-name), "theme");
    }

    h6{
      @include c-typo-style("heading-m", map-get($zaux-styles-typography, $style-name), "theme");
    }

    p,
    li {
      @include c-typo-style("text-m", map-get($zaux-styles-typography, $style-name), "theme");
    }

    blockquote{
      background-color: tokenColor("set1", "color6");
      font-style: italic;
      overflow:hidden;
      position:relative;
      &:before{
        position:absolute;
        top:-10px;
        color:tokenColor("set1", "color5");
        opacity:0.5;
        right:tokenSpacer(4);
        content:'"';
        font-size: tokenTypoSizeFs(10);
      }
    }

    ul li {
      &:before {
        color: tokenColor("set1", "cyan1");
      }
    }

    strong,
    b {
      color: tokenColor("set1", "black1");
      font-weight:$zaux-typo-fw-bold;
      a{
        color:tokenColor("set1","color1");
      }
    }

    a {
      @include c-typo-style("link", map-get($zaux-styles-typography, $style-name), "theme");
      @at-root .#{$zaux-system-classname-loaded} &:not([class*="btn"]) {
        transition: color $zaux-transition-1, opacity $zaux-transition-1;
      }
      > * {
        color:tokenColor("set1","color1");
      }
    }

  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.zaux-theme--dark1 {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    color: tokenColor("set1", "white1");
  }

  a {
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1, opacity $zaux-transition-1;
    }

    &:link,
    &:visited,
    &:hover {
      color: tokenColor("set1", "white1");
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
